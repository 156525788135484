<script>
	import { videoInView } from '~/utils/store';

	export let data;
	export let active;
	export let nextVideo;

	let video;

	const onTimeUpdate = (event) => {
		if (active) {
			const video = event.target;
			const progress = (video.currentTime * 100) / video.duration;

			document.querySelector('.how-it-works-layout').setAttribute('style', `--video-percent: ${progress}%`);

			if (progress >= 99) {
				nextVideo();
			}
		}
	};

	$: {
		if (video) {
			if (active && $videoInView) {
				setTimeout(() => video?.play(), 300);
			} else if (active && !$videoInView) {
				video?.pause();
			} else {
				video?.pause();
				video.currentTime = 0;
			}
		}
	}
</script>

<div class={$$props.class} class:relative={true} class:invisible={!active}>
	<video
		bind:this={video}
		class="w-full object-fill object-top rounded-[40px] lg:rounded-[50px]"
		muted
		playsinline
		preload="auto"
		on:timeupdate={onTimeUpdate}
		style="aspect-ratio: 335/712;"
	>
		<source src={data.av1} type="video/mp4" />
		<source src={data.hevc} type="video/mp4" />
		<source src={data.h264} type="video/mp4" />
		Your browser does not support the video tag.
	</video>
	<svg
		class="absolute top-0 h-full"
		
		viewBox="0 0 343 712"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<path
			d="M292.622 0H48.229C21.6003 0 0 21.5861 0 48.1973V662.915C0 689.526 21.6003 711.112 48.229 711.112H292.622C319.251 711.112 340.852 689.526 340.852 662.915V48.1973C340.852 21.5861 319.251 0 292.622 0ZM336.172 661.228C336.172 686.188 315.911 706.436 290.934 706.436H49.9174C24.9404 706.436 4.67976 686.188 4.67976 661.228V49.8846C4.67976 24.924 24.9404 4.67668 49.9174 4.67668H290.916C315.893 4.67668 336.153 24.924 336.153 49.8846V661.228H336.172Z"
			fill="black"
		/>
		<path
			d="M290.916 4.67676H49.9176C24.9405 4.67676 4.67993 24.924 4.67993 49.8847V661.228C4.67993 686.188 24.9405 706.436 49.9176 706.436H290.916C315.893 706.436 336.154 686.188 336.154 661.228V49.8847C336.154 24.924 315.893 4.67676 290.916 4.67676ZM333.805 659.871C333.805 684.299 313.984 704.107 289.54 704.107H51.2756C26.8308 704.107 7.01063 684.299 7.01063 659.871V51.2418C7.01063 26.8131 26.8308 7.02427 51.2756 7.02427H289.558C314.003 7.02427 333.823 26.8314 333.823 51.2602V659.871H333.805Z"
			fill="#1A1A1A"
		/>
		<path
			d="M290.931 706.673H49.9142C24.8454 706.673 4.45631 686.298 4.45631 661.227V49.884C4.43796 24.8316 24.8454 4.43762 49.9142 4.43762H290.931C316 4.43762 336.389 24.8133 336.389 49.8656V661.209C336.389 686.261 316 706.655 290.931 706.655V706.673ZM49.9142 4.89612C25.1023 4.89612 4.89676 25.0884 4.89676 49.884V661.227C4.89676 686.023 25.0839 706.215 49.8958 706.215H290.913C315.724 706.215 335.912 686.041 335.912 661.227V49.884C335.912 25.0884 315.724 4.91446 290.913 4.91446H49.9142V4.89612ZM289.555 704.326H51.2722C26.7356 704.326 6.78701 684.372 6.78701 659.87V51.2411C6.78701 26.739 26.754 6.78513 51.2722 6.78513H289.555C314.091 6.78513 334.04 26.739 334.04 51.2411V659.852C334.04 684.372 314.073 704.308 289.555 704.308V704.326ZM51.2722 7.24363C26.9926 7.24363 7.24581 26.9774 7.24581 51.2411V659.852C7.24581 684.115 26.9926 703.849 51.2722 703.849H289.555C313.834 703.849 333.581 684.115 333.581 659.852V51.2411C333.581 26.9774 313.834 7.24363 289.555 7.24363H51.2722Z"
			fill="black"
		/>
		<path d="M341.918 148.755H340.835V224.829H341.918V148.755Z" fill="url(#paint0_linear_1128_22167)" />
		<path
			d="M341.937 224.829V148.773L342.488 149.434C342.213 154.881 342.231 218.74 342.488 224.169L341.937 224.829Z"
			fill="url(#paint1_linear_1128_22167)"
		/>
		<path
			d="M187.285 709.848H153.554C152.875 709.848 152.269 709.939 152.086 710.049L150.599 710.691C150.324 710.875 151.058 711.058 152.068 711.058H188.79C189.799 711.058 190.533 710.875 190.258 710.691L188.771 710.049C188.588 709.921 187.982 709.848 187.303 709.848H187.285Z"
			fill="black"
		/>
		<path
			d="M200.532 39.4297H139.144C132.244 39.4297 126.646 33.836 126.646 26.9219C126.646 20.0261 132.244 14.4324 139.144 14.4324H200.532C207.432 14.4324 213.029 20.0261 213.029 26.9219C213.029 33.8177 207.432 39.4297 200.532 39.4297Z"
			fill="black"
		/>
		<path
			d="M197.079 32.4063C200.1 32.4063 202.548 29.9594 202.548 26.941C202.548 23.9226 200.1 21.4757 197.079 21.4757C194.059 21.4757 191.61 23.9226 191.61 26.941C191.61 29.9594 194.059 32.4063 197.079 32.4063Z"
			fill="url(#paint2_linear_1128_22167)"
		/>
		<rect x="191.855" y="21.7688" width="11.0112" height="11.004" fill="url(#pattern0)" />
		<path
			d="M197.079 30.0587C198.802 30.0587 200.198 28.6628 200.198 26.9409C200.198 25.219 198.802 23.8231 197.079 23.8231C195.356 23.8231 193.959 25.219 193.959 26.9409C193.959 28.6628 195.356 30.0587 197.079 30.0587Z"
			fill="url(#paint3_linear_1128_22167)"
		/>
		<path
			d="M199.793 26.9413C199.793 28.4451 198.563 29.6739 197.058 29.6739C195.554 29.6739 194.324 28.4635 194.324 26.9413C194.324 25.4191 195.535 24.2086 197.058 24.2086C198.582 24.2086 199.793 25.4191 199.793 26.9413Z"
			fill="url(#paint4_linear_1128_22167)"
		/>
		<path
			style="mix-blend-mode:multiply"
			d="M199.793 26.9413C199.793 28.4451 198.563 29.6739 197.058 29.6739C195.554 29.6739 194.324 28.4635 194.324 26.9413C194.324 25.4191 195.535 24.2086 197.058 24.2086C198.582 24.2086 199.793 25.4191 199.793 26.9413Z"
			fill="url(#paint5_radial_1128_22167)"
		/>
		<path
			style="mix-blend-mode:screen"
			d="M199.541 28.353C199.541 27.8211 199.1 27.381 198.568 27.381C198.036 27.381 197.595 27.8211 197.595 28.353C197.595 28.8849 198.036 29.325 198.568 29.325C199.1 29.325 199.541 28.8849 199.541 28.353Z"
			fill="url(#paint6_radial_1128_22167)"
		/>
		<defs>
			<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
				<use xlink:href="#image0_1128_22167" transform="scale(0.166667)" />
			</pattern>
			<linearGradient
				id="paint0_linear_1128_22167"
				x1="341.404"
				y1="148.755"
				x2="341.404"
				y2="224.829"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#3B3B3B" />
				<stop offset="0.02" stop-color="#E6E6E6" />
				<stop offset="0.04" stop-color="#333333" />
				<stop offset="0.08" />
				<stop offset="0.11" stop-color="#666666" />
				<stop offset="0.9" stop-color="#313131" />
				<stop offset="0.94" />
				<stop offset="0.96" stop-color="#333333" />
				<stop offset="0.98" stop-color="#CCCCCC" />
				<stop offset="1" stop-color="#2C2C2C" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1128_22167"
				x1="342.213"
				y1="148.773"
				x2="342.213"
				y2="224.847"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#A0A0A0" />
				<stop offset="0.06" stop-color="#333333" />
				<stop offset="0.19" stop-color="#8A8A8A" />
				<stop offset="0.81" stop-color="#8A8A8A" />
				<stop offset="0.92" stop-color="#333333" />
				<stop offset="1" stop-color="#A0A0A0" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_1128_22167"
				x1="197.079"
				y1="32.0578"
				x2="197.079"
				y2="21.3106"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#666666" />
				<stop offset="1" stop-color="#010104" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_1128_22167"
				x1="197.079"
				y1="24.0065"
				x2="197.079"
				y2="30.1504"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#0B131C" />
				<stop offset="1" stop-color="#354039" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_1128_22167"
				x1="199.004"
				y1="28.867"
				x2="195.134"
				y2="24.9947"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#231F20" />
				<stop offset="0.08" stop-color="#212226" />
				<stop offset="0.2" stop-color="#1F2C37" />
				<stop offset="0.33" stop-color="#1A3C53" />
				<stop offset="0.47" stop-color="#13537B" />
				<stop offset="0.62" stop-color="#0A70AE" />
				<stop offset="0.78" stop-color="#0095EE" />
				<stop offset="0.8" stop-color="#0387D6" />
				<stop offset="0.84" stop-color="#0D689F" />
				<stop offset="0.88" stop-color="#154D71" />
				<stop offset="0.91" stop-color="#1B394E" />
				<stop offset="0.95" stop-color="#1F2A34" />
				<stop offset="0.98" stop-color="#222225" />
				<stop offset="1" stop-color="#231F20" />
			</linearGradient>
			<radialGradient
				id="paint5_radial_1128_22167"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(197.058 26.9413) scale(2.73445 2.73265)"
			>
				<stop stop-color="#231F20" />
				<stop offset="0.2" stop-color="#165068" />
				<stop offset="0.38" stop-color="#0C78A1" />
				<stop offset="0.54" stop-color="#0594CB" />
				<stop offset="0.66" stop-color="#01A6E4" />
				<stop offset="0.73" stop-color="#00ADEE" />
				<stop offset="0.76" stop-color="#01A5E3" />
				<stop offset="0.81" stop-color="#0693C8" />
				<stop offset="0.87" stop-color="#0D749B" />
				<stop offset="0.94" stop-color="#184A5E" />
				<stop offset="1" stop-color="#231F20" />
			</radialGradient>
			<radialGradient
				id="paint6_radial_1128_22167"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(198.568 28.353) scale(0.97266 0.972016)"
			>
				<stop stop-color="#CCCCCC" />
				<stop offset="0.02" stop-color="#C4C4C4" />
				<stop offset="0.22" stop-color="#898989" />
				<stop offset="0.41" stop-color="#585858" />
				<stop offset="0.59" stop-color="#313131" />
				<stop offset="0.75" stop-color="#161616" />
				<stop offset="0.89" stop-color="#050505" />
				<stop offset="1" />
			</radialGradient>
			<image
				id="image0_1128_22167"
				width="6"
				height="6"
				xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAACXBIWXMAAAsSAAALEgHS3X78AAAAb0lEQVQImQXBwQ3CMAxA0e+0siF2uNJT1RWA/Qdo1YGIRGrek3XbsveOquLuRDTOYxd5LksCmN2oXgGIaMxqhiC0R6PeK6UUMpM5IgCo1WkRiBR6/1Lcg6lMqCqqxnUNxhgIwOv9STPjNwYA57HLH1ZiHKEIgmfwAAAAAElFTkSuQmCC"
			/>
		</defs>
	</svg>
</div>
