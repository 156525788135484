<svg class={$$props.class} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M2.66675 9.33331C2.66675 7.12417 4.45761 5.33331 6.66675 5.33331H25.3334C27.5426 5.33331 29.3334 7.12417 29.3334 9.33331V11.1111C29.3334 12.3384 28.3385 13.3333 27.1112 13.3333V13.3333C25.8839 13.3333 24.889 14.3282 24.889 15.5555V16.4444C24.889 17.6717 25.8839 18.6666 27.1112 18.6666V18.6666C28.3385 18.6666 29.3334 19.6616 29.3334 20.8889V22.6666C29.3334 24.8758 27.5426 26.6666 25.3334 26.6666H6.66674C4.45761 26.6666 2.66675 24.8758 2.66675 22.6666V20.8889C2.66675 19.6616 3.66167 18.6666 4.88897 18.6666V18.6666C6.11627 18.6666 7.11119 17.6717 7.11119 16.4444V15.5555C7.11119 14.3282 6.11627 13.3333 4.88897 13.3333V13.3333C3.66167 13.3333 2.66675 12.3384 2.66675 11.1111V9.33331Z"
		stroke="currentColor"
		stroke-width="3"
	/>
	<path
		d="M13.3333 9.33331L13.3333 22.6666"
		stroke="currentColor"
		stroke-width="3"
		stroke-linecap="round"
		stroke-dasharray="4 4"
	/>
</svg>
